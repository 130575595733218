.spinner-wrapper{
      padding: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      position: fixed;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background: #0000004a;
      z-index: 99999;
  }
  .spinner-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #FFDD24;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }